import { createStore, applyMiddleware, compose } from "redux";
import { persistStore } from "redux-persist";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";

import { isDev } from "@/utils/helpers/isDev";

import rootReducer from "./reducers/rootReducers";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares = [
  thunk,
  createLogger({
    predicate: () => isDev(),
    collapsed: true,
  }),
];

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);

export const persistor = persistStore(store);
