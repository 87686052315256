import React from 'react';
import './button.scss';
import {Tooltip } from 'antd';

export const ButtonItem = ({ icon, tooltip, onClick, active, isMenu }) => {

  if (!isMenu) {
    return (
      <Tooltip title={tooltip || ''}>
        <button onClick={onClick} className={`my-btn ${active ? 'active' : ''}`}>
        {icon}
      </button>
    </Tooltip>
    )
  } else {
    return (
        <button onClick={onClick} className={`my-btn ${active ? 'active' : ''}`}>
        {icon} {tooltip || ''}
        </button>
    )
  }
}
