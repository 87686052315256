import { appTypes } from "./app.types";

const INITIAL_STATE = {
  sidebar_collapse: false,
  sub_nabar_title: window.location.pathname.replace("/espace_utilisateur/"," ").toUpperCase(),
  errors: "",
};

const appReducer = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case appTypes.APP_SIDEBAR_COLLAPSE:
      return {
        ...state,
        sidebar_collapse: action.payload,
        // loading: false,
      };
    
    case appTypes.APP_SUBNAVBAR_TITLE:
      return {
        ...state,
        sub_nabar_title: action.payload,
        // loading: false,
      };

    default:
      return state;
  }
};

export default appReducer;
