import { profilTypes } from "./profil.types";


const INITIAL_STATE = {
  itemList: [],
  loading: false,
  errors: "",
};

const profilReducer = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case profilTypes.GET_ALL_PROFIL:
      return {
        ...state,
        itemList: action.payload,
        loading: false,
      };

    case profilTypes.PROFIL_LOADING:
      return {
        ...state,
        loading: action.payload,
        errors: "",
      };

    default:
      return state;
  }
};

export default profilReducer;
