import React, { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import "./SafeErrorBoundaries.style.scss";
import { useNavigate } from 'react-router-dom';
import { Loader } from '../Loader/Loader';
import { Button, Result, message } from 'antd';

const ErrorFallback = ({ error }) => {

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const goTo = () => {
    navigate(`../`);
  }

  const sendReport = () => {
    console.log('error', error);
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      message.success("Opération éffectuée avec succès");
      goTo();
    }, 2000);
  }

  return (
    <div className="error-boundary-container">
      <div className="middle-card">
        <Result
          status="warning"
          title="L'opération a échouée"
          subTitle="Une erreur s'est produite lors de l'execution"
          extra={[
            <Button style={{borderRadius: 0}} type="primary" onClick={sendReport}>
              Remonter l'information
            </Button>,
            <Button style={{borderRadius: 0}} onClick={goTo}>Retour</Button>,
          ]}
        >

        </Result>
        {isLoading && <Loader />}
      </div>
    </div>
  );
};

const myErrorHandler = ({ error, info }) => {
  console.log('error', error);
  console.log('info', info);
}

export const SafeErrorBoundaries = ({ children }) => {

  return (
    <ErrorBoundary
      onError={myErrorHandler}
      FallbackComponent={ErrorFallback}>
      {children}
    </ErrorBoundary>
  )
}
