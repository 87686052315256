import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 40, fontWeight: 1000 }} spin rev={undefined} />;

export const Loader = () => {
  return (
    <Spin className='detail-loader' tip="Chargement..." indicator={antIcon}></Spin>
  )
}

 
