import React from 'react';
import { Breadcrumb } from 'antd';
import './title.scss';
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { selectSubnavbarTitle } from '@/store/reducers/app/app.selectors';

const TitleItem = ({ title }) => {
  return (
    <Breadcrumb className="subtitle"
      items={[
        {
          title: title,
        },
      ]}
    />
  );
}


const mapStateToProps = createStructuredSelector({
  title: selectSubnavbarTitle,
});

const connector = connect(mapStateToProps);
export const Title = connector(TitleItem);


