export const routePaths = {
    espace_connexion: "/espace_connexion",
    signin: "connexion",
    signup: "inscription",
    forget: "mot_oublie",
    otp: "code_otp",
    editPassword: "modifier_mot_de_passe",

    espace_user: "/espace_utilisateur",
    changePassword: "change_pass",
    home: "bienvenue",
    annuaire: "annuaire",
    departement: 'departement',
    tribu: 'tribu',
    parametre: 'parametres',
    compte: 'compte',
    programme: 'programme',

    param_pole: 'pole',
    param_direction: 'direction',
    param_commune: 'commune',
    param_profil: 'profil',
    param_site: 'site',
    param_batiment: 'batiment',
    param_local: 'local',
    param_salle: 'salle',
    param_personnel: 'personnel',
};