import {
  selectAuthToken,
} from "@/store/reducers/auth/auth.selectors";
import { setAuthToken } from "@/utils/helpers/setAuthToken";
import { connect } from "react-redux";
import { Navigate, useRoutes } from "react-router-dom";
import { createStructuredSelector } from "reselect";

import { protectedRoutes } from "./protected.routes";
import { publicRoutes } from "./public.routes";
import { routePaths } from "./routesPath";


const AppRoutes = ({ token }) => {
  console.log("Auth token", token);
  const auth = true;
  // const auth = !!token;
  setAuthToken(token);

  const commonRoutes = auth ? [{ path: "*", element: <Navigate to={routePaths.espace_user} /> }] : [{ path: "*", element: <Navigate to={routePaths.espace_connexion} /> }];

  const routes = auth ? protectedRoutes : publicRoutes;
  const element =useRoutes([...routes, ...commonRoutes]);

  return <>{element}</>;
};

const mapStateToProps = createStructuredSelector({
  token: selectAuthToken,
});

const connector = connect(mapStateToProps);
export default connector(AppRoutes);
