import { Routes, Route, Navigate } from "react-router-dom";
import { SafeErrorBoundaries } from "@/utils/hooks/SafeErrorBoundaries/SafeErrorBoundaries";
import { routePaths } from "@/Routers/routesPath";
import { lazyImport } from '@/utils/helpers/lazyImport';
import * as React from "react";

const  Signin  = lazyImport(
    () => import("@/pages/connection-space/signin/Signin")
);

const  Signup  = lazyImport(
    () => import("@/pages/connection-space/signup/Signup")
);

const  Forget  = lazyImport(
    () => import("@/pages/connection-space/forget/Forget")
);

const  Otp  = lazyImport(
    () => import("@/pages/connection-space/otp/Otp")
);

const  EditPassword  = lazyImport(
    () => import("@/pages/connection-space/editPassword/EditPassword")
);

export const ConnectionSpaceRoutes = () => {
    return (
        <Routes>
            <Route path={routePaths.signin} element={
                <SafeErrorBoundaries>
                    <Signin />
                </SafeErrorBoundaries>
            } />
            <Route path={routePaths.signup} element={
                <SafeErrorBoundaries>
                    <Signup />
                </SafeErrorBoundaries>
            } />
            <Route path={routePaths.forget} element={
                <SafeErrorBoundaries>
                    <Forget />
                </SafeErrorBoundaries>
            } />
            <Route path={routePaths.otp} element={
                <SafeErrorBoundaries>
                    <Otp />
                </SafeErrorBoundaries>
            } />
            <Route path={routePaths.editPassword} element={
                <SafeErrorBoundaries>
                    <EditPassword />
                </SafeErrorBoundaries>
            } />
            <Route path='' element= {  <Navigate to={routePaths.signin}/>  } />
        </Routes>
    )
}