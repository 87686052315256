import React, { useState } from 'react';
import './Sidebar.style.scss';
import { ButtonItem } from '@/components/Button';
import { GoldOutlined, UserOutlined, ToolOutlined } from '@ant-design/icons';
import { routePaths } from "@/Routers/routesPath";
import { useNavigate } from 'react-router-dom';

import { store } from '@/store';
import { setSubnavbarTitle } from '../../../../store/reducers/app/app.actions';

const Sidebar = ( ) => {

  const navigate = useNavigate();

  const goto = (title) => {
    if (title === 'Home') {
      navigate(routePaths.home);
      store.dispatch(setSubnavbarTitle(window.location.pathname.replace("/espace_utilisateur/"," ").toUpperCase()));
    }
    if (title === 'Annuaire') {
      navigate(routePaths.annuaire);
      store.dispatch(setSubnavbarTitle(window.location.pathname.replace("/espace_utilisateur/"," ").toUpperCase()));
    }
    if (title === 'Parametres') {
      navigate(routePaths.parametre);
      store.dispatch(setSubnavbarTitle(window.location.pathname.replace("/espace_utilisateur/"," ").toUpperCase()));
    }
  }

  return (
    <>
      <ul className={`sidebar`}>
        <li className="sidebar-item" key={0}>
          <ButtonItem tooltip="Tableau de bord" icon={<GoldOutlined />} onClick={() => { goto('Home') }} active={window.location.pathname === "/espace_utilisateur/bienvenue"} />
        </li>
        <li className="sidebar-item" key={1}>
          <ButtonItem tooltip="Annuaire" icon={<UserOutlined />} onClick={() => { goto('Annuaire') }} active={window.location.pathname === "/espace_utilisateur/annuaire"} />
        </li>
        <li className="sidebar-item" key={2}>
          <ButtonItem tooltip="Parametres" icon={<ToolOutlined />} onClick={() => { goto('Parametres') }} active={window.location.pathname === "/espace_utilisateur/parametres"} />
        </li>
      </ul>
      
    </>
  )
}

export default Sidebar