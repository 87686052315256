import { createSelector } from "reselect";

export const selectApp = (state) => state.app;

export const selectSidebarCollapse = createSelector(
  [selectApp],
  (app) => app.sidebar_collapse
);

export const selectSubnavbarTitle = createSelector(
  [selectApp],
  (app) => app.sub_nabar_title
);

