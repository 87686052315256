import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { authTypes } from "./auth/auth.types";

import authReducer from "./auth/auth.reducers";
import appReducer from "./app/app.reducers";
import profilReducer from "./profil/profil.reducers";

export const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
  blacklist: [],
};

const topReducer = combineReducers({
  auth: authReducer,
  app: appReducer,
  profil: profilReducer,
});

const rootReducer = (state, action) => {
  // when RESET action is dispatched it will reset redux state
  if (action.type === authTypes.LOGOUT) {
    state = undefined;
  }
  return topReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
