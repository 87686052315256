import React from 'react';
import "./UserSpace.style.scss";
import Navbar from './layout/Navbar/Navbar';
import { Title } from '../../components/Title';
import Sidebar from './layout/Sidebar/Sidebar';
import { UserSpaceRoutes } from './routes';

export const UserSpace = () => {

  return (
    <div className='userspace'>
      <nav className="navbar">
        <Navbar />
      </nav>
      <div className="navbar-title">
        <Title />
      </div>
      <div className={`body`}>
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="content">
          <UserSpaceRoutes />
        </div>
      </div>
    </div>
  )
}



 