import React, { useState } from 'react';
import './Navbar.style.scss';
import { Avatar, Badge, Card, Space, Dropdown, Upload, Modal, Divider } from 'antd';
import { BellOutlined, MenuUnfoldOutlined, MenuFoldOutlined, PlusOutlined, UserSwitchOutlined, MessageFilled, LockOutlined } from '@ant-design/icons';
import { ButtonItem } from '@/components/Button';
import { setSidebarCollapse } from '@/store/reducers/app/app.actions';
import { selectSidebarCollapse } from '@/store/reducers/app/app.selectors';
import { store } from '@/store';
import { logoutAsync } from "@/store/reducers/auth/auth.actions";
import { selectAuthUser } from "@/store/reducers/auth/auth.selectors";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { ModalItem } from '../../../../components/Modal';
import { InputDateItem, InputItem, SelectItem } from './../../../../components/Input/index';
import { Grid } from '@mui/material';

const items = [
    {
        label: 'Profil',
        key: '0',
    },
    {
        type: 'divider',
    },
    {
        label: 'Se deconnecter',
        key: '1',
    },
];

const options = [
      {
        value: 'Homme',
      },
      {
        value: 'Femme',
      },
      {
        value: 'Adolescant',
      },
      {
        value: 'Enfant',
      },
    ];

const NavbarPage = ({ isCollapse }) => {

    const [open, setOpen] = useState(false);
    const [openModalProfil, setOpenModalProfil] = useState(false);

    const showModal = () => {
        setOpen(true);
    };

    const handleDropdownItemClick = (e) => {
        console.log(e);
        if (e.key === '0') {
            setOpenModalProfil(true);
        }
    };

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState([]);
    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    return (
        <div className='navbar'>
            <div className="navbar-part left">
                <div className="navbar-burger">
                    {
                        !isCollapse ?
                            <ButtonItem icon={<MenuUnfoldOutlined />} onClick={() => { store.dispatch(setSidebarCollapse(true)) }} />
                            :
                            <ButtonItem icon={<MenuFoldOutlined />} onClick={() => { store.dispatch(setSidebarCollapse(false)) }} />
                    }
                </div>
                <span>Vase d'honneur</span>
            </div>
            <div className="navbar-part right">
                <Badge count={5} color='#000' onClick={showModal}>
                    <BellOutlined style={{ fontSize: '20px', color: '#000' }} />
                </Badge>
                <Dropdown
                    menu={{
                        onClick: handleDropdownItemClick,
                        items: items,
                    }}
                    trigger={['click']}
                >
                    <Avatar
                        style={{
                            backgroundColor: 'var(--bs-teal)',
                            verticalAlign: 'middle',
                            cursor: 'pointer'
                        }}
                        size="large"
                        gap={4}
                    >
                        A
                    </Avatar>
                </Dropdown>
            </div>
            <ModalItem
                setOpen={setOpen}
                open={open}
                okText={"J'ai compris"}
                title="Quoi de neuf ?"
            >
                <Space
                    direction="vertical"
                    size="middle"
                    style={{
                        padding: '0 10px',
                        width: '100%',
                        maxHeight: '300px',
                        overflowY: 'auto',
                        overflowX: 'hidden'
                    }}
                >
                    <Badge.Ribbon text="Hippies">
                        <Card title="Pushes open the window" size="small">
                            and raises the spyglass.
                        </Card>
                    </Badge.Ribbon>
                    <Badge.Ribbon text="Hippies" color="pink">
                        <Card title="Pushes open the window" size="small">
                            and raises the spyglass.
                        </Card>
                    </Badge.Ribbon>
                    <Badge.Ribbon text="Hippies" color="red">
                        <Card title="Pushes open the window" size="small">
                            and raises the spyglass.
                        </Card>
                    </Badge.Ribbon>
                    <Badge.Ribbon text="Hippies" color="cyan">
                        <Card title="Pushes open the window" size="small">
                            and raises the spyglass.
                        </Card>
                    </Badge.Ribbon>
                    <Badge.Ribbon text="Hippies" color="green">
                        <Card title="Pushes open the window" size="small">
                            and raises the spyglass.
                        </Card>
                    </Badge.Ribbon>
                    <Badge.Ribbon text="Hippies" color="purple">
                        <Card title="Pushes open the window" size="small">
                            and raises the spyglass.
                        </Card>
                    </Badge.Ribbon>
                    <Badge.Ribbon text="Hippies" color="volcano">
                        <Card title="Pushes open the window" size="small">
                            and raises the spyglass.
                        </Card>
                    </Badge.Ribbon>
                    <Badge.Ribbon text="Hippies" color="magenta">
                        <Card title="Pushes open the window" size="small">
                            and raises the spyglass.
                        </Card>
                    </Badge.Ribbon>
                </Space>
            </ModalItem>
            <ModalItem
                setOpen={setOpenModalProfil}
                open={openModalProfil}
                okText={"Mettre a jour"}
                title="Personnaliser votre profil"
            >
                <Space
                    direction="vertical"
                    size="middle"
                    style={{
                        padding: '0 10px',
                        width: '100%',
                        maxHeight: '300px',
                        overflowY: 'auto',
                        overflowX: 'hidden'
                    }}
                >
                    <div className='set-picture'>
                        <Upload
                            action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                            listType="picture-card"
                            fileList={fileList}
                            onPreview={handlePreview}
                            onChange={handleChange}
                        >
                            {fileList.length >= 1 ? null : uploadButton}
                        </Upload>
                        <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                            <img
                                alt="example"
                                style={{
                                    width: '100%',
                                }}
                                src={previewImage}
                            />
                        </Modal>
                    </div>
                    <div className='profil-form'>
                        <Divider plain>Informations personnelles</Divider>
                        <Grid container rowSpacing={2} columnSpacing={2}>
                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                <InputItem label={'Nom'} icon={<UserSwitchOutlined />} />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                <InputItem label={'Prenom'} icon={<UserSwitchOutlined />} />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                <InputItem label={'Email'} icon={<MessageFilled />} />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                <InputDateItem label={'Date de naissance'} />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                <SelectItem placeholder={'Genre'} options={options} />
                            </Grid>
                        </Grid>
                        <Divider plain>Authentification</Divider>
                        <Grid container rowSpacing={2} columnSpacing={2}>
                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                <InputItem label={'Mot de passe'} icon={<LockOutlined />} password={true} />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <InputItem label={'Confirmer mot de passe'} icon={<LockOutlined />} password={true} />
                            </Grid>
                        </Grid>
                    </div>
                </Space>
            </ModalItem>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    user: selectAuthUser,
    isCollapse: selectSidebarCollapse,
});

const mapDispatchToProps = (dispatch) => ({
    getOutAsync: () => dispatch(logoutAsync()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
const Navbar = connector(NavbarPage);

export default Navbar;