import { appTypes } from "./app.types";

export const setSidebarCollapse = (sidebar) => ({
    type: appTypes.APP_SIDEBAR_COLLAPSE,
    payload: sidebar,
});

export const setSubnavbarTitle = (title) => ({
    type: appTypes.APP_SUBNAVBAR_TITLE,
    payload: title,
});


