import React, { useState } from 'react';
import './modal.scss';
import { Modal } from 'antd';

export const ModalItem = ({ 
  children, 
  open,
  setOpen,
  okText,
  title
}) => {

  const [confirmLoading, setConfirmLoading] = useState(false);


  const handleOk = () => {
      setConfirmLoading(true);
      setTimeout(() => {
          setOpen(false);
          setConfirmLoading(false);
      }, 2000);
  };

  const handleCancel = () => {
      setOpen(false);
  };

  return (
    <Modal
      title={title}
      open={open}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      cancelText="Fermer"
      okText={okText}
    >
      {children}
    </Modal>
  )
}
