import React, {useState} from 'react';
import './inputs.scss';
import { Input, Select, Tag, Radio, DatePicker } from 'antd';

export const InputItem = ({ label, icon, password }) => {
  if (password) {
    return(
      <Input.Password placeholder={label} prefix={icon} size='large' />
    )
  } else {
    return (
      <Input placeholder={label} prefix={icon} size='large' />
    )
  } 
}


export const InputDateItem = ({label}) => (
    <DatePicker placeholder={label} size='large' />
);


// const options = [
//   {
//     value: 'gold',
//   },
//   {
//     value: 'lime',
//   },
//   {
//     value: 'green',
//   },
//   {
//     value: 'cyan',
//   },
// ];
const tagRender = (props) => {
  const { label, value, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color={value}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
      }}
    >
      {label}
    </Tag>
  );
};
export const SelectItem = ({placeholder, multiple, options}) => (
  <Select
    mode={multiple ? "multiple": ''}
    tagRender={tagRender}
    style={{
      width: '100%',
    }}
    placeholder={placeholder}
    options={options}
    size='large'
  />
);



const optionSwitch = [
  {
    label: 'Carte',
    value: 'Carte',
  },
  {
    label: 'Tableau',
    value: 'Tableau',
  },
];

export const RadioItem = () => {
  const [value, setValue] = useState('Carte');

  const onChange = ({ target: { value } }) => {
    console.log('radio checked', value);
    setValue(value);
  };

  return (
    <div className='radio'>
      <Radio.Group options={optionSwitch} onChange={onChange} value={value} optionType="button" />
    </div>
  );
};