import { Routes, Route, Navigate } from "react-router-dom";
import { SafeErrorBoundaries } from "@/utils/hooks/SafeErrorBoundaries/SafeErrorBoundaries";
import { routePaths } from "@/Routers/routesPath";
import { lazyImport } from '@/utils/helpers/lazyImport';

const  Home  = lazyImport(
    () => import("@/pages/user-space/home/Home")
);

const  Annuaire  = lazyImport(
    () => import("@/pages/user-space/annuaire/Annuaire")
);

const  Setting  = lazyImport(
    () => import("@/pages/user-space/setting/Setting")
);


export const UserSpaceRoutes = () => {
    return (
        <Routes>
            <Route path={routePaths.home} element={
                <SafeErrorBoundaries>
                    <Home />
                </SafeErrorBoundaries>
            } />
            <Route path={routePaths.annuaire} element={
                <SafeErrorBoundaries>
                    <Annuaire />
                </SafeErrorBoundaries>
            } />
            <Route path={routePaths.parametre} element={
                <SafeErrorBoundaries>
                    <Setting />
                </SafeErrorBoundaries>
            } />
           <Route path='' element= {  <Navigate to={routePaths.home}/>  } />  
        </Routes>
    )
}



